<template>
  <div v-if="detailData">
    <div class="key-visual">
      <div class="contents">
        <div class="txt" :style="`color:${detailData.detailFont}`">
          <span v-html="detailData.project" />
          <br />
          {{ detailData.projectServiceName }}
        </div>
        <div class="info">
          <span class="name" :style="`color:${detailData.detailFont}`">
            {{ detailData.projectClientName }}
          </span>
          <span class="date" :style="`color:${detailData.detailFont}`">
            <!--            {{ detailData.releaseDate | dateFormat }}-->
            {{ detailData.releaseDate }}
          </span>
          <span class="desc" :style="`color:${detailData.detailFont}`">
            {{ detailData.projectType }}
          </span>
        </div>
      </div>
      <div
        class="bg"
        :style="{backgroundImage: `url(${imgUrl(detailData.pcDetailImagePhysicalName)})`}"
      ></div>
    </div>

    <div
      class="project-desc"
      :style="{backgroundImage: `url(${imgUrl(detailData.projectDesc.worksFieldImageList[0].pcImagePhysicalName)})`}"
    >
      <div class="inner" v-html="handleNewLine(detailData.projectDesc.fieldContents)"></div>
    </div>

    <div
      class="project-goal"
      :style="{backgroundImage: `url(${imgUrl(detailData.projectGoal.worksFieldImageList[0].pcImagePhysicalName)})`}"
    >
      <div class="inner">
        <span class="tit" :style="`color:${detailData.projectGoal.color}`">Project Goal</span>
        <img
          :src="imgUrl(detailData.projectGoal.worksFieldImageList[1].pcImagePhysicalName)"
          class="logo"
          alt=""
        />
        <span
          class="desc"
          v-html="handleNewLine(detailData.projectGoal.fieldContents)"
          :style="`color:${detailData.projectGoal.color}`"
        ></span>
      </div>
    </div>

    <div
      v-for="(item, index) in detailData.projectInfo"
      :key="`info${index}`"
      class="project-info"
      :style="{backgroundColor: item.bgColor, color: item.color}"
    >
      <div class="contents">
        <div class="title-box">
          <div
            class="tit"
            :style="{color: item.color}"
            v-html="handleNewLine(item.fieldTitleOne)"
          ></div>
          <div
            class="desc"
            :style="{color: item.color}"
            v-html="handleNewLine(item.fieldContents)"
          ></div>
        </div>
        <img :src="imgUrl(item.worksFieldImageList[0].pcImagePhysicalName)" class="logo" alt="" />
      </div>
    </div>

    <div class="contents" v-if="detailData.designConcept.length > 0">
      <h2 class="section-title">Design Concept</h2>
    </div>

    <div
      v-for="(item, index) in detailData.designConcept"
      :key="`concept${index}`"
      class="project-info design-concept"
      :style="{backgroundColor: item.bgColor, color: item.color}"
    >
      <div class="contents">
        <div class="title-box">
          <div
            class="tit"
            :style="{color: item.color}"
            v-html="handleNewLine(item.fieldTitleOne)"
          ></div>
          <div class="desc" :style="{color: item.color || ''}" v-html="item.fieldContents"></div>
        </div>
        <img :src="imgUrl(item.worksFieldImageList[0].pcImagePhysicalName)" class="logo" alt="" />
      </div>
    </div>

    <div class="interview">
      <div class="inner">
        <div class="info">
          <h2 class="section-title">PROJECT INTERVIEW</h2>
          <p
            v-if="detailData.interview"
            v-html="handleNewLine(detailData.interview.interviewContents)"
          ></p>
          <ul>
            <li v-for="(item, index) in detailData.creditList" :key="index">
              <strong>{{ item.creditPosition }}</strong>
              <span>{{ item.creditName }}</span>
            </li>
          </ul>
        </div>
        <!--       <img :src="require('@/assets/images/@temp/work_detail3.png')" alt="" />-->
        <div class="pm-img" v-if="detailData.interview">
          <img :src="imgUrl(detailData.interview.intervieweePcImagePhysicalName)" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {apiWorksDetail} from "@/api";
  import {Power0, TweenMax} from "gsap";
  import {mapGetters} from "vuex";
  import {commonMethods} from "@/utils/common-methods";

  export default {
    name: "worksDetail",
    data() {
      return {
        scene: null,
        detailData: null
      };
    },
    computed: {
      ...mapGetters(["GE_CONT_HEIGHT"])
    },
    watch: {
      GE_CONT_HEIGHT() {
        if (this.$el.offsetParent) {
          this.init();
        }
      }
    },
    activated() {
      this.init();
    },
    mixins: [commonMethods],
    methods: {
      init() {
        this.scene?.destroy(true);
        const keyVisual = document.querySelector(".key-visual");

        const tween = TweenMax.to(keyVisual.querySelector(".bg"), 1, {
          y: keyVisual.clientHeight,
          ease: Power0.easeInOut
        });
        const tween2 = TweenMax.to(keyVisual.querySelector(".txt"), 1, {
          y: keyVisual.clientHeight + keyVisual.querySelector(".txt").clientHeight,
          ease: Power0.easeInOut
        });
        const tween3 = TweenMax.to(keyVisual.querySelector(".info"), 1, {
          y: keyVisual.clientHeight + 200,
          ease: Power0.easeInOut
        });
        this.scene = this.$scrollmagic
          .scene({
            triggerElement: keyVisual,
            triggerHook: 0,
            duration: keyVisual.clientHeight * 2
          })
          .setTween([tween, tween2, tween3]);
        this.$scrollmagic.addScene(this.scene);
        document.querySelectorAll(".project-info").forEach(el => {
          const tw = TweenMax.fromTo(
            el.querySelector(".contents"),
            1,
            {
              y: 300
            },
            {
              y: 0,
              ease: Power0.easeInOut
            }
          );
          const sc = this.$scrollmagic
            .scene({
              offset: 0,
              triggerElement: el,
              triggerHook: 1,
              duration: 1000
            })
            .setTween(tw);
          this.$scrollmagic.addScene(sc);
        });
      },
      async fetchData() {
        try {
          const {
            data: {data: response}
          } = await apiWorksDetail(this.$route.params.id);
          this.detailData = response;
          console.log(response);
        } catch (error) {
          console.error(error);
        }
      }
    },
    created() {
      this.fetchData();
    },
    deactivated() {
      this.scene?.destroy(true);
    }
  };
</script>
<style lang="scss" scoped>
  .interview {
    min-width: $globalWidth;
    /*margin-top: 50px;*/
    background-color: #f9f9f9;
    .inner {
      margin: 0 auto;
      display: flex;
      align-items: flex-end;
      @include globalWidth();
      /*min-height: 733px;*/
      .info {
        width: 50%;
        padding-bottom: 124px;
        p {
          margin-top: 100px;
          font-size: 18px;
          line-height: 30px;
          strong {
            font-weight: bold;
          }
        }
        ul {
          margin-top: 55px;
          font-size: 14px;
          line-height: 24px;
          color: #6e6e6e;

          li {
            display: flex;
            padding: 4px 0;
            strong {
              flex: 0 0 305px;
            }
            span {
              word-break: keep-all;
            }
          }
        }
      }
      .pm-img {
        padding-top: 100px;
        width: 50%;
        text-align: center;
        img {
          vertical-align: top;
        }
      }
    }
  }
  .section-title {
    margin-top: 110px;
    font-size: 26px;
    line-height: 40px;
    font-weight: bold;
    @include montserrat();
    text-transform: uppercase;
  }
  .key-visual {
    position: relative;
    min-height: 1500px;
    height: 150vh;
    overflow: hidden;
    min-width: $globalWidth;
    .contents {
      z-index: 2;
      display: flex;
      flex-direction: column;
      padding-top: 120px;
      box-sizing: border-box;
      justify-content: center;
      align-items: flex-start;
      height: 100vh;
      min-height: 800px;
      position: relative;
      .txt {
        @include montserrat();
        font-weight: bold;
        font-size: 100px;
        color: #fff;
        line-height: 140px;
        // span {
        // }
      }
      .info {
        display: flex;
        margin-top: 100px;
        color: #fff;
        font-size: 20px;
        .name {
          font-family: "Noto Sans KR", Montserrat, sans-serif;
        }
        .date {
          @include montserrat();

          margin-left: 60px;
        }
        .desc {
          @include montserrat();
          margin-left: 120px;
        }
      }
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      min-width: $globalWidth;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
  }
  .project-desc {
    display: flex;
    align-items: center;
    min-width: $globalWidth;
    max-width: 1760px;
    box-sizing: border-box;
    margin: 0 auto;
    height: 600px;
    line-height: 70px;
    font-size: 44px;
    font-weight: 700;
    background-position: 50% 50%;
    .inner {
      margin: 0 auto;
      @include globalWidth();
    }
  }
  .project-goal {
    min-width: $globalWidth;
    max-width: 1760px;
    height: 880px;
    margin: 0 auto;
    &:before {
      content: "";
      height: 1px;
      display: block;
    }
    .inner {
      position: relative;
      margin: 0 auto;
      @include globalWidth();
      .tit {
        margin-top: 114px;
        @include montserrat();
        display: block;
        font-size: 26px;
        font-weight: bold;
        line-height: 30px;
        text-transform: uppercase;
        color: #fff;
      }
      .logo {
        margin-top: 93px;
      }
      .desc {
        display: block;
        margin-top: 90px;
        font-size: 18px;
        line-height: 30px;
        color: #fff;
      }
    }
  }
  .project-info {
    min-width: $globalWidth;
    padding: 120px 0;
    .contents {
      color: #000;
      .title-box {
        display: flex;
        align-items: flex-start;
        .tit {
          flex: 0 0 auto;
          min-width: 300px;
          text-transform: uppercase;
          @include montserrat();
          display: block;
          position: relative;
          box-sizing: border-box;
          font-size: 14px;
          line-height: 40px;
          padding: 0 10px 0 27px;
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            height: 12px;
            width: 12px;
            margin-top: -6px;
            border-radius: 50%;
            background: $red;
          }
        }
        .desc {
          flex: 1 1 auto;
          display: block;
          font-size: 24px;
          line-height: 40px;
          font-weight: bold;
          letter-spacing: -0.05em;
          word-break: keep-all;
        }
      }
      img {
        margin-top: 120px;
      }
    }
    &.design-concept {
      margin-top: -70px;

      /*   padding: 50px 0;*/
    }
  }
</style>
